.dropdown-custom{
	.item{
		display: flex;

		a{
			display: block;
			flex-grow: 1;
		}

		.btn{
			border: none;
			border-radius: 0;
			@include button-variant(#af373a, #af373a);
			border-left: 1px solid rgba(#000, .2);

			.fas{
				display: inline-block;
				transition: transform .3s linear;
			}

			&[aria-expanded="true"] {
				.fas{
					transform: rotate(-180deg);
				}
			}
		}
	}

	.menu-dropdown{
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;

		@include media-breakpoint-down(lg) {
			border-top: 1px solid rgba(#000,.2);

			li{
				background: #FFF;

				a{
					color: #000;
				}

				&.active a,
				a.active,
				a:hover{
					background: #DDD;
				}

				& ~ li{
					border-top: 1px solid rgba(#000,.2);
				}
			}
		}
	}
}


.categorias__topo{
	background: #af373a;
	color: #FFF;
	padding-left: 0;
	margin-bottom: 0;

	.categoria__header{
		display: flex;

		a{
			display: block;
			padding: 10px 15px;
			flex-grow: 1;
		}

		button{
			@extend .btn;
			@include button-variant(#af373a, #af373a);
			border-radius: 0;
			border: none;
			border-left: 1px solid rgba(#000,.2);
		}
	}

	.categoria + .categoria{
		border-top: 1px solid rgba(#000,.2);
	}

	.subcategoria{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
		border-top: 1px solid rgba(#000,.2);

		li{
			background: #FFF;

			& ~ li {
				border-top: 1px solid rgba(#000,.2);
			}

			a{
				display: block;
				padding: 10px 15px;
				color: #000;
			}

			.active a,
			a.active {
				background: $theme;
				color: #000;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		border-top: 1px solid rgba(#000,.2);
	}
}
