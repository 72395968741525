.carousel-indicators-custom{
	margin-bottom:0;
	margin-left:0;
	width:auto;
	display:inline-flex;
	font-family: 'poppinsbold';
	color:#bb2a2a;
	margin-right:0;
	bottom:50%;
	flex-direction:column;
	max-width:1170px;
	padding-left:15px;
	padding-right:15px;
	margin-left:auto;
	align-items:flex-end;
	margin-right:auto;

	@include media-breakpoint-down(md){
		display: none !important;
	}

	li{
		text-indent:0;
		display:inline-block;
		height:auto;
		margin:0;
		border:none;
		font-size:13px;
		background-color:transparent;
		color:#FFF;
		margin-bottom:10px;
		position:relative;
		opacity:1;

		&:before{
			content:'';
			display:inline-block;
			width:0;
			height:3px;
			position:relative;
			bottom:3px;
			margin-right:3px;
			line-height:1;
			background-color:transparent;
			vertical-align:middle;
			@include transitionRepetitivo(background-color,width);
		}

		&.active{
			color:#bb2a2a;

			&:before{
				background-color:#bb2a2a;
				width:8px;
			}

		}

	}

}