.mbl-controls{
	display:flex;
	flex-wrap:wrap;
	justify-content:space-between;
	background-color:#FFF;
	@include transitionRepetitivo();
	box-shadow:0 0 8px rgba(#000,0.5);

	.logo{
		padding:5px;
		max-width:60px;
	}

	.btn-responsivo{
		border:none;
		box-shadow:none;

		.bar-menu{
			display:block;
			width:32px;
			height:3px;
			background-color:#000;
			position:relative;

			&,
			&:before,
			&:after{
				@include transitionRepetitivo();
			}

			@include beforeAfter{
				content:'';
				display:block;
				width:100%;
				height:100%;
				background-color:inherit;
				position:absolute;
				left:0;
			}

			&:before{
				bottom:10px;
			}

			&:after{
				top:10px;
			}

		}

	}

}