.estrelinhas{

	& > *{
		font-size:14px;
	}

	.fa-star-full{

		&:before{
			content:'\f005';
			@extend .fa;
		}

	}

	&.estrelinhas-gray{

		.fa-star{
			color:#b9b9b9;
		}

		.fa-star-full{
			color:#efce4a;
		}

	}

}