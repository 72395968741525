@mixin transitionRepetitivo($um : all, $dois : null){
  
  @if $dois{
    $um: $um 0.6s;
    $dois : $dois 0.6s;
    transition:$um,$dois linear;
  }@else{
    $um: $um 0.6s;
    $dois : null;
    transition:$um linear;
  }
    
}