.paginacao-custom{

	.page-item{

		.page-link{
			color:#535353;
		}

		&.active{

			.page-link{
				background-color:#7f7f7f;
				border-color:#7f7f7f;
				color:#FFF;
			}

		}

	}

}