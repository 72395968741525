.acordiao-grupo{
	margin-bottom:22px;
	border-bottom:1px solid #353535;
	padding-bottom:12px;
	@include transitionRepetitivo();

	.title{
		color:#333 !important;
		@include transitionRepetitivo(color);
	}

	&:hover{
		border-bottom-color:$theme;

		.title{
			color:$theme !important;
		}

	}

	.acordiao-flex{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;

		.btn{
			padding:0;

			&:focus{
				box-shadow:none;
			}

			&[aria-expanded="true"]{

				.fas:before{
					content:"\f054";
				}

			}

		}

	}

}