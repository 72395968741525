.icon{
	background-image: url('#{$imgs}/sprite.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 155px 101px;
	
	&.icon-cadeado{
		width: 22px;
		height: 30px;
		background-position: -98px 0px;
	}
	&.icon-exclamacao{
		width: 34px;
		height: 66px;
		background-position: 0px 0px;
	}
	&.icon-gv8{
		width: 44px;
		height: 24px;
		background-position: -44px 0px;
	}
	&.icon-left-black-hover{
		width: 25px;
		height: 25px;
		background-position: 0px -76px;
	}
	&.icon-left-black{
		width: 25px;
		height: 25px;
		background-position: -35px -76px;
	}
	&.icon-right-black-hover{
		width: 25px;
		height: 25px;
		background-position: -70px -76px;
	}
	&.icon-right-black{
		width: 25px;
		height: 25px;
		background-position: -130px 0px;
	}
	&.icon-sacola{
		width: 26px;
		height: 31px;
		background-position: -44px -34px;
	}
}


