.radio-custom{
	cursor:pointer;

	& + .radio-custom{

		@include media-breakpoint-up(lg){
			margin-left:18px;
		}

	}

	span{
		@include transitionRepetitivo();
	}

	.circulo{
		position:relative;
		display:inline-block;
		width:1.3em;
		height:1.3em;
		border-radius:100%;
		border:2px solid transparent;
		vertical-align:middle;
		@include transitionRepetitivo(background-color);
		background-color:#878787;
		margin-left:0.6em;

		&:before{
			content:'';
			display:block;
			position:absolute;
			top:(0.2857142857142857 / 2) * 1em;
			left:(0.2857142857142857 / 2) * 1em;
			width:calc(100% - 0.2857142857142857em);
			height:calc(100% - 0.2857142857142857em);
			border-radius:100%;
			transform:scale(0);
			background-color:$theme;
			// border:2px solid #FFF;
			@include transitionRepetitivo(transform);
		}

	}

	input{
		position:fixed;
		left:-200vw;

		&:checked ~ label > .circulo{
			background-color:$theme2;

			&:before{
				transform:scale(1);
			}

		}

	}

	&.radio-custom-black{

		.circulo{
			background-color:transparent;
			border-color:#343434;

			&:before{
				background-color:transparent;
			}

		}

		input:checked ~ label{

			.circulo{
				background-color:#FFF;
				
				&:before{
					background-color:#343434;
				}

			}

		}

	}

}

.nav-tabs-custom{

	.nav-link.active{

		.radio-custom{

			.circulo{
				background-color:#FFF;
				
				&:before{
					background-color:#343434;
					transform:scale(1);
				}

			}

		}

	}

}