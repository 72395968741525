.card-embalagens{
	width:180px;
	max-width:180px;
	margin-right:auto;
	margin-left:auto;
	height:180px;
	background-color:#FFF;
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
	align-items:center;
	position:relative;
	margin-bottom:30px;

	.figure-card{
		margin-bottom:0;

		.figure-controls{
			position:absolute;
			top:8px;
			left:7px;
			color:#1d1d1d;
			font-size:18px;

			@include media-breakpoint-up(lg){
				opacity:0;
				@include transitionRepetitivo(opacity);
			}

		}

	}

	&:hover{


		.figure-controls{

			@include media-breakpoint-up(lg){
				opacity:1;
			}

		}

	}

}