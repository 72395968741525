.media-incrivel{

	.media-icon{
		font-size:30px;
		
		.fas,
		.fa,
		.icon{
			position:relative;
		}

		.circle{
			position:absolute;
			bottom:calc(100% - 17px);
			left:calc(100% - 8px);
			width:29px;
			height:29px;
			line-height:29px;
			font-size:16px;
			background-color:#d1d1d1;
			text-align:center;
			border-radius:100%;
			overflow:hidden;
			text-overflow:ellipsis;
			font-family: 'montserratmedium';
		}

		@include media-breakpoint-up(lg){
			margin-right:10px;
		}

	}

	.media-text{
		font-size:16px;
		line-height:1;
	}

	@include media-breakpoint-up(lg){
		display:flex;
		align-items:center;
		flex-wrap:wrap;
	}

	&:hover{

		.fas{

			&.fa-lock{

				&:before{
					content:'\f3c1';
				}

			}

		}

	}

}

a + a  .media-incrivel{
	margin-left:48px;
	position:relative;

	&:before{
		content:'';
		display:block;
		position:absolute;
		top:0;
		right:calc(100% + 25px);
		background-color:#e4e4e4;
		height:100%;
		width:1px;
	}

}
