.menu-categorias{
	padding-left:0;
	list-style-type:none;
	margin-bottom:0;
	overflow:auto;

	li{

		a{
			display:block;
			padding:10px 15px;
			border-top:1px solid transparent;
			border-bottom:1px solid transparent;

			@include media-breakpoint-down(lg){

				&:before{
					content:'-';
					display:inline-block;
					margin-right:5px;
				}

			}

		}

		&.active > a,
		&:hover  > a{
			border-color:darken($theme2,20%);
		}

	}

	@include media-breakpoint-up(xl){
		height:100% !important;
		display:block !important;
		position:fixed;
		top:0;
		width:220px;
		left:-220px;
		z-index:1000;
		background-color:$theme2;
		@include transitionRepetitivo(left);
	}

}