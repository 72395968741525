.menu-principal{
	padding-left:0;
	list-style-type:none;
	margin-bottom:0;
	background-color:#af373a;
	color:#FFF;
	font-size:14px;

	@include media-breakpoint-up(xl){
		display:flex;
		flex-grow:1;
		padding-left:30px;
		flex-wrap:wrap;
		justify-content:space-between;
		align-items:center;

		&:before{
			left:100%;
		}

		.btn{
			display: none;
		}

		.menu-dropdown{
			position: absolute;
			left: 0;
			top: 100%;
			background: #8a2b2e;
			z-index: 999;
			min-width: 250px;
			max-height: calc(100vh - 250px);
			overflow: auto;

			a{
				display: block;
				padding: 10px 15px;
			}

			.active a,
			a.active,
			a:hover{
				background: darken(#8a2b2e, 10%);
			}
		}

	}

	& > li{

		& > a,
		.item a{

			@include media-breakpoint-up(xl){
				position:relative;
				padding-top:18px;
				padding-bottom:18px;
				display:inline-block;

				&:before{
					content:'';
					display:block;
					position:absolute;
					top:0;
					width:100%;
					height:5px;
					left:0;
					@include transitionRepetitivo();
					background-color:transparent;
				}

			}


			@include media-breakpoint-down(lg){
				display:block;
				padding:10px 15px;
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
			}

		}

		@include media-breakpoint-up(xl){

			&.active > a,
			&.active > .item a,
			&:hover > a,
			&:hover > .item a{
				color:$theme;

				&:before{
					background-color:$theme;
				}

			}

			&:hover .menu-dropdown{
				display: block;
			}

		}

		@include media-breakpoint-down(lg){

			& ~ li{
				border-top: 1px solid rgba(#000,.2);
			}

			&.active > a,
			&:hover > a{
				background-color:$theme;
				color:#FFF;
				border-color:darken($theme,10%);
			}

		}

	}

}
