.card-contato-contatos{
	position:relative;
	text-align:center;
	margin-bottom:20px;

	@include media-breakpoint-up(lg){

		&:before{
			content:'';
			display:block;
			width:15px;
			height:15px;
			background-color:#1e1b1d;
			border-radius:100%;
			margin-right:auto;
			margin-bottom:16px;
			margin-left:auto;
		}

	}
	
	.fa,
	.fas{
		font-size:19px;
		color:#1e1b1d;
	}

	.texto{
		font-size:14px;
		font-family: 'montserratmedium';
		color:#1e1e1e;

		@include media-breakpoint-down(md){
			padding-bottom:15px;
		}

		.bld{
			font-family: 'montserratbold';
		}

	}

	a:hover{
		color:$theme;
	}

	&:after{

		@include media-breakpoint-down(md){
			content:'';
			display:block;
			border-top:1px dashed #1e1b1d;
			padding-top:15px;
		}

	}

}