.backdrop {
	width: 100%;
	height: 100%;
	background: rgba(#000,.7);
	left: 0;
	top: 0;
	z-index: 1000;
	position: fixed;
	animation: fadeIn .4s linear;
	backdrop-filter: blur(4px);

	&.backdrop--hide{
		animation: fadeOut .4s linear;
	}
}
