@font-face {
    font-family: 'montserratsemibold';
    src: url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.eot');
    src: url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.svg#montserratsemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratregular';
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot');
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratmedium';
    src: url('#{$fonts}/Montserrat_medium/montserrat-medium-webfont.eot');
    src: url('#{$fonts}/Montserrat_medium/montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Montserrat_medium/montserrat-medium-webfont.woff2') format('woff2'),
         url('#{$fonts}/Montserrat_medium/montserrat-medium-webfont.woff') format('woff'),
         url('#{$fonts}/Montserrat_medium/montserrat-medium-webfont.ttf') format('truetype'),
         url('#{$fonts}/Montserrat_medium/montserrat-medium-webfont.svg#montserratmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratlight';
    src: url('#{$fonts}/montserrat_light/montserrat-light-webfont.eot');
    src: url('#{$fonts}/montserrat_light/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_light/montserrat-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_light/montserrat-light-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_light/montserrat-light-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_light/montserrat-light-webfont.svg#montserratlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratbold';
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot');
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratblack';
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot');
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinssemibold';
    src: url('#{$fonts}/poppins-semibold/poppins-semibold-webfont.eot');
    src: url('#{$fonts}/poppins-semibold/poppins-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/poppins-semibold/poppins-semibold-webfont.woff2') format('woff2'),
         url('#{$fonts}/poppins-semibold/poppins-semibold-webfont.woff') format('woff'),
         url('#{$fonts}/poppins-semibold/poppins-semibold-webfont.ttf') format('truetype'),
         url('#{$fonts}/poppins-semibold/poppins-semibold-webfont.svg#poppinssemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinslight';
    src: url('#{$fonts}/poppins-light/poppins-light-webfont.eot');
    src: url('#{$fonts}/poppins-light/poppins-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/poppins-light/poppins-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/poppins-light/poppins-light-webfont.woff') format('woff'),
         url('#{$fonts}/poppins-light/poppins-light-webfont.ttf') format('truetype'),
         url('#{$fonts}/poppins-light/poppins-light-webfont.svg#poppinslight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsbold';
    src: url('#{$fonts}/poppins-bold/poppins-bold-webfont.eot');
    src: url('#{$fonts}/poppins-bold/poppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/poppins-bold/poppins-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/poppins-bold/poppins-bold-webfont.woff') format('woff'),
         url('#{$fonts}/poppins-bold/poppins-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/poppins-bold/poppins-bold-webfont.svg#poppinsbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanslight';
    src: url('#{$fonts}/opensans-light/opensans-light-webfont.eot');
    src: url('#{$fonts}/opensans-light/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans-light/opensans-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans-light/opensans-light-webfont.woff') format('woff'),
         url('#{$fonts}/opensans-light/opensans-light-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans-light/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}