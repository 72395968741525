.nav-tabs-custom{
	border:none;

	.nav-item{

		.nav-link{
			border:none;
			padding:0 15px 20px 15px;
			border-radius:0;

			label{
				margin-bottom:0;
			}

		}

	}

}