.redes__sociais__topo{

	@include media-breakpoint-down(lg) {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding: 20px 15px;
		font-size: 20px;
		border-top: 1px solid rgba(#000,.2);

		a{
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			margin: 0 5px;
			width: 2em;
			height: 2em;
			background: #000;
			color: #FFF;
		}
	}
}
