.btn-expanded{
	padding: .375rem 1.5rem;

	&.btn-sm{
		padding: .25rem 1rem;
	}

	&.btn-lg{
		padding: .5rem 2rem;
	}
}

.btn{
	white-space:normal;

	&.btn-quase-black{
		@include button-variant(#1e1b1d,#1e1b1d);
		color:#FFF;
	}

	&.btn-theme{
		@include button-variant($theme,$theme);
		color:#000000;

		@include hover-focus{
			@include button-variant(#1e1b1d,#1e1b1d);
			color:#FFF;
		}

	}

	&.btn-theme-2{
		@include button-variant($theme2,$theme2);
		color:#FFF;

		@include hover-focus{
			@include button-variant(#1e1b1d,#1e1b1d);
			color:#FFF;
		}

	}

	&.btn-theme-2-to-theme{
		@include button-variant($theme2,$theme2);
		color:#FFF;

		@include hover-focus{
			@include button-variant($theme,$theme);
			color:#1d1d1d !important;
		}

	}

	&.btn-black-to-theme2{
		@include button-variant(#1d1d1d,#1d1d1d);
		color:#FFF;

		@include hover-focus{
			@include button-variant($theme2,$theme2);
			color:#FFF;
		}

	}

	&.btn-transparent-border-black{
		@include button-variant(transparent,#1e1b1d);
		color:#1e1b1d;
		border-width:3px;

		@include hover-focus{
			@include button-variant(#1e1b1d,#1e1b1d);
			color:#FFF !important;
		}

	}

	&.btn-radius-30{
		border-radius:30px !important;
	}

	&.min-width-200{
		min-width:200px;
	}

	&.min-width-222{
		min-width:222px;
	}

	&.fnt-14-mnt-bld-height-50,
	&.fnt-14-mnt-bld-height-40,
	&.fnt-14-mnt-bld-height-36{
		font-family: 'montserratbold';
		font-size:14px;
	}

	&.fnt-14-mnt-bld-height-50{
		padding:14px 15px;
	}

	&.fnt-14-mnt-bld-height-40{
		padding:12px 15px;
	}

	&.fnt-14-mnt-bld-height-36{
		padding:8px 15px;
	}

}