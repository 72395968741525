.card-colecoes{
	margin-bottom:55px;

	.figure-card{
		margin-bottom:12px;
		text-align:center;
		position:relative;

		lazyimage{
			border-radius:100%;
			overflow:hidden;
		}

		.figure-controls{
			display:flex;
			flex-wrap:wrap;
			position:absolute;
			top:0;
			padding-top:8px;
			padding-left:8px;
			left:0;
			font-size:18px;
			align-items:center;
			justify-content:center;
			color:#1d1d1d;

			@include media-breakpoint-up(lg){
				opacity:0;
				@include transitionRepetitivo(opacity);
			}

		}

	}

	.body-card{

		.card-title,
		.card-subtitle{
			margin-bottom:8px;
		}

		.card-title{
			font-size:17px;
			color:#363636;
			font-family: 'montserratbold';
		}

		.card-subtitle{
			font-size:15px;
			color:#666666;
		}

	}

	&:hover{


		.figure-card{

			.figure-controls{
				opacity:1;
			}

		}

	}

}