.rodape{


	.rodape-newsletter{
		background-color:#231f20;
		color:#FFF;
		padding-top:45px;
		text-align:center;
		background-position:center center;
		background-repeat:no-repeat;
		background-size:cover;
		background-attachment:fixed;
		padding-bottom:138px;
		position:relative;

		&:before{
			content:'';
			display:block;
			position:absolute;
			bottom:0;
			left:0;
			width:100%;
			height:130px;
			background-image:url('#{$imgs}/bg-onda-rodape.png');
			background-repeat:no-repeat;
			background-position:center center;
		}

	}

	.rodape-content{
		text-align:center;

		.logo-footer{
			display:inline-block;
		}

		.text-rodape,
		.copyright{
			margin-bottom:2px;
			font-size:10px;
			font-family: 'poppinslight';
		}

		.copyright{
			color:#000;
			padding-bottom:20px;
		}

	}

}

.btn__whatsapp {
	position: fixed;
	left: 15px;
	bottom: 15px;
	z-index: 109;
	box-shadow: 0 0 8px rgba(#000,.8);
	font-size: 40px;
	background: #01AB4C;

	&:hover{
		text-decoration: none;
		animation: rubberBand 1s linear;
	}

	&,
	.btn__whatsapp__wrapper{
		width: toEm(60,40);
		height: toEm(60,40);
		border-radius: 50%;
	}

	.btn__whatsapp__wrapper{
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		clip-path: inset(0 0 0 0 round 50%);
	}

	svg{
		fill: #FFF;
	}
}
