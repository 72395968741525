.mobile__controls {
	display: grid;
	grid-template-columns: 50px 50px 1fr min-content 50px;
	background: #FFF;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 999;
	box-shadow: 0 0 8px rgba(#000,.8);

	.btn{
		border-radius: 0;
		@include button-variant(#FFF, #FFF);
	}

	.btn-user-toggler{
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000;

		.fas,
		.icon{
			font-size: 25px;
			color: inherit;
		}

		.icon{
			background: none !important;
			@extend .fas,.fa-user;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&[aria-expanded="true"] {
			position: relative;

			&::after{
				content: '';
				position: absolute;
				border-bottom: 10px solid $theme2;
				border-right: 10px solid transparent;
				border-left: 10px solid transparent;
				left: 50%;
				bottom: -2px;
				transform: translateX(-50%);
				z-index: 20
			}
		}

		span{
			@include sr-only;
		}
	}

	.btn__carrinho{
		display: flex;
		align-items: center;
		justify-content: center;

		.carriho__qtde{
			font-size: 12px;
			transform: translate(10px, -50%);
			background: $theme2;
			color: #FFF;
		}
	}

	.carrinho__legenda{
		@include sr-only;
	}

	.logo{
		margin: 0;
		padding: 10px;
		text-align: center;

		img{
			max-width: 100%;
			max-height: 60px;
			width: auto;
			height: auto;
		}
	}

	.user__area{
		display: flex;
	}

	.dropdown-menu{
		border-radius: 0;
		margin-top: 0;
		z-index: 10;
		background: $theme2;

		a{
			display: block;
			padding: 10px 15px;
			color: #FFF;
		}
	}
}
