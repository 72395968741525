.form-cadastro{

	form{

		.custom-select{
			background-image:none !important;
		}

		.form-group{

			.form-control{
				border-radius:6px;
				margin-bottom:16px;
				border-color:transparent;
				min-height:50px;
				background-color:#e0e0e0;
				@include sombra(#e0e0e0);
				@include place(#9f9d9d);
				font-size:14px;
			}



		}

		.form-control-diferente{

			.form-control{
				@include place(#343434);
			}

		}

		.form-group-escondido{

			label{
				width:100%;

				.texto{
					display:inline-block;
					margin-bottom:0.5rem;
				}

				.form-control{
					display:flex;
					align-items:center;
					justify-content:space-between;
					flex-wrap:wrap;
					color:#4b4b4b;

					.selecione{
						width:90%;
					}

					.fas{
						padding-left:5px;
						font-size:17px;
						width:10%;
					}

				}

			}

			.form-control,
			label{
				margin-bottom:0;
			}

			.campo-escondido{
				position:fixed;
				top:0;
				left:-3000vw;
			}

		}

	}

}