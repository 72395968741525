.banner{
	text-align:center;
	z-index: 0;

	.carousel-item{
		z-index:60;
		margin-bottom:0;
	}

	.carousel-controls{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		align-items:center;

		.clearfix{

			& > *{
				position:relative;
				z-index:100;
			}

		}

	}

	.effect-banner{
		position:absolute;
		bottom:0;
		left:0;
		width:100%;
		height:182px;
		z-index:100;

		@include media-breakpoint-down(md){
			display:none;
		}

	}

}

.max-height{
	overflow:hidden;
	text-overflow:ellipsis;

	&.max-height-8{
		max-height:(14 * 1.5) * 8px;
	}

}

.sobre{
	padding-top:15px;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;

	@include media-breakpoint-down(md){
		background-image:none !important;
	}

	.col-lg-7{
		padding-bottom:55px;
		padding-top:60px;
	}

}

.destaques{
	padding-top:75px;
	padding-bottom:45px;
}

.mais-vistos{
	padding-top:25px;
	padding-bottom:30px;
}
