.card-add{
	text-align:center;
	margin-bottom:45px;
	max-width:220px;
	margin-left:auto;
	margin-right:auto;

	.bg-compras,
	.title-card{
		font-family: 'montserratbold';
	}

	.figure-card{
		min-height:270px;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:center;
		position:relative;
		margin-bottom:12px;

		&:before,
		.bg-compras{
			display:block;
			position:absolute;

			@include media-breakpoint-up(lg){
				opacity:0;
				@include transitionRepetitivo(opacity);
			}

		}

		&:before{
			content:'\f067';
			@extend .fa;
			font-size:16px;
			color:#1d1d1d;
			top:10px;
			left:5px;
		}

		.bg-compras{
			left:0;
			bottom:0;
			background-color:#5f5f5f;
			color:#FFF;
			width:100%;
			height:32px;
			display:flex;
			flex-wrap:wrap;
			justify-content:flex-end;
			padding:5px 10px;
			align-items:center;
			font-size:13px;

			.fas{
				font-size:18px;
				margin-right:5px;
			}

		}

	}

	.body-card{

		& > *{
			line-height:1;
		}

		.estrelinhas{
			margin-bottom:8px;
		}

		.title-card{
			font-size:17px;
			color:#363636;
			margin-bottom:8px;
		}

		.desc-card{
			margin-bottom:10px;
		}

	}

	&:hover{


		.figure-card:before,
		.bg-compras{
			opacity:1;
		}

	}

}