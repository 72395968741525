.header-section{
	font-size:30px;

	& > *{
		line-height:1;
		margin-bottom:0;
	}

	.subtitle-gray,
	.title-section{
		font-family: 'montserratbold';
	}

	.subtitle-gray{
		font-size:(20 / 30) * 1em;
		color:#7c7c7c;
	}

	.title-section{
		color:#af373a;
		font-size:(65 / 30) * 1em;
	}

	.subtitle-black{
		font-size:(35 / 30) * 1em;
		color:#1d1d1d;
		font-family: 'montserratblack';

		&.subtitle-black-peq{
			font-size:(20 / 30) * 1em;
			font-family: 'montserratmedium';
		}

	}

	@include media-breakpoint-down(sm){
		font-size:22px;
	}

}