.internas{
	padding-top:82px;
	padding-bottom:30px;

	.secao-produtos-estampa{
		background-color:#f1f3f3;
		padding-top:30px;
		padding-bottom:35px;

		.title{
			color:#1d1d1d;
			font-size:35px;
			font-family: 'montserratblack';
			margin-bottom:0;
		}

	}

	&.internas-cadastro{

		.box-content{
			max-width:976px;
			margin-left:auto;
			margin-right:auto;
		}

	}

	&.internas-carrinho{
		padding-bottom:0;
		padding-top:0;

		.section-exibicao{
			padding-bottom:50px;
		}

	}

	&.internas-colecoes-detalhes{
		padding-top:0;
		padding-bottom:0;

		.secao-descricao{
			padding-bottom:30px;
			padding-top:0;

			.figure-section{
				text-align:center;
			}

		}

	}

	&.internas-contato{
		padding-bottom:0;

		.secao-exibicao-contato{
			padding-bottom:30px;

			.row{
				margin-bottom:30px;
			}

		}

		.secao-contatos{
			background-color:#daedef;
			padding-top:45px;
			padding-bottom:20px;
		}

	}

	&.internas-login{
		padding-bottom:0;
		padding-top:45px;

		.secao-exibicao-contato{
			padding-bottom:20px;
		}
		
		section.bg-azul-claro{
			padding-top:42px;
			padding-bottom:40px;
		}

	}

	&.internas-personalizados-detalhes{
		padding-bottom:0;
		appererence: teste;

		.secao-produtos-estampa{
			border-top:70px solid #FFF;
		}

		.form-cadastro{

			label{
				font-family: 'poppinssemibold';
			}

		}

	}

	&.internas-produtos{
		padding-top:0;
		padding-bottom:0;

		.section-produtos-internas{
			padding-top:15px;
			background-repeat:no-repeat;
			background-size:cover;
			background-position:center center;

			.header-section{
				font-size:27px;
			}

			.col-lg-5{

				figure{
					max-width:236px;
				}

			}

		}

		.bg-azul-claro{
			padding-top:65px;
			padding-bottom:60px;
		}

	}

	&.internas-produtos-detalhes{
		padding-bottom:0;

		.form-cadastro{

			label{
				font-family:'poppinssemibold';
			}

			.borda-legal{

				&:before{
					top:calc(50% + 3px);
					right:10px;
				}

			}

			.custom-select{
				background-image:none;
			}

		}

	}

	&.internas-produtos-detalhes-internas{
		padding-bottom:0;
	}

	&.internas-quem-somos{
		padding-top:32px;
		background-color:#f2f2f2;
		padding-bottom:0;

		.section-missao{
			background-color:#FFF;
			padding-top:35px;
			padding-bottom:15px;

			.title{
				font-size:14px;
				color:$theme;
				font-family: 'montserratblack';
			}

		}

	}

}