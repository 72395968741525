.menu__mobile{
	width: 250px;
	height: 100%;
	background: #FFF;
	z-index: 1050;
	left: 0;
	top: 0;
	overflow: auto;
	position: fixed;

	&:not(.menu__mobile--show){
		transform: translateX(-100%);
	}

	&.menu__mobile--show{
		transform: translateX(0);
		animation: slideInLeft .4s linear;
	}

	&.menu__mobile--hide{
		transform: translateX(-100%);
		animation: slideOutLeft .4s linear;
	}

	.logo{
		padding: 40px 15px;
		text-align: center;
		margin-bottom: 0;
	}
}
