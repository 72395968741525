@import "./mobile__controls";
@import "./menu__mobile";
@import "./search__mobile";
@import "./backdrop";
@import "./redes__sociais__topo";

@import "./faixa__topo";

.btn__carrinho{
	.icon{
		position: relative;
	}

	.carriho__qtde{
		position: absolute;
		background: #d1d1d1;
		color: #000;
		border-radius: 50%;
		width: 29px;
		height: 29px;
		display: flex;
		align-items: center;
		justify-content: center;
		right: 0;
		top: 0;
		transform: translate(50%, -50%);
	}
}


@include media-breakpoint-down(lg) {
	.topo{
		padding-top: 80px;
	}
}
