.redes-sociais{

	& > *{
		@include margin(0,4);
	}

	&.redes-sociais-topo{
		padding-left:11px;

		& > *{
			font-size:16px;
			padding-right:6px;
			border-right:1px solid #383838;

			&:last-child{
				border-right:none;
			}

			&:hover{
				color:#969696;
			}

		}

	}

	&.redes-sociais-rodape{
		margin-bottom:20px;

		& > *{
			color:#FFF;
			background-color:#000000;
			@include margin(0,5);
			@include bolinha(28,46);
		}

	}

}