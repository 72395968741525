a,
.btn{
	@include transitionRepetitivo();
}

.form-control{
	@include transitionRepetitivo(box-shadow,border-color);
	height:auto;
	resize:none;
}

a{
	color: inherit;
	text-decoration:none;

	@include hover-focus{
		text-decoration:none;
		color:inherit;
	}

}

.internas img{
	@include img-fluid();
}

// FONTS USADAS:
body{
	font-size:14px;
	color:#666666;
	font-family: 'montserratregular';
}

.logo-mobile{
	margin-bottom:0;
}

.mnt-semi-bld{
	font-family: 'montserratsemibold';
}

.mnt-reg{
	font-family: 'montserratregular';
}

.mnt-med{
	font-family: 'montserratmedium';
}

.mnt-lgt{
	font-family: 'montserratlight';
}

.mnt-bld{
	font-family: 'montserratbold';
}

.mnt-blk{
	font-family: 'montserratblack';
}

.pops-semi-bld{
	font-family: 'poppinssemibold';
}

.pops-lgt{
	font-family: 'poppinslight';
}

.pops-bld{
	font-family: 'poppinsbold';
}

.opns-lgt{
	font-family: 'open_sanslight';
}

.fnt-25{
	font-size:25px;
}

.bg-azul-claro{
	background-color:#daedef;
}

@include text-emphasis-variant('.text-theme',$theme);
@include text-emphasis-variant('.text-theme-2',$theme2);
@include text-emphasis-variant('.text-black-claro',#202020);

.max-width-290{
	max-width:290px;
	margin-left:auto;
	margin-right:auto;
}

.max-width-60{
	max-width:60px;
	margin-left:auto;
	margin-right:auto;
}

.icon-left-black,
.icon-right-black{
	transition:none;
}

.icon-left-black:hover{
	@extend .icon-left-black-hover;
}

.icon-right-black:hover{
	@extend .icon-right-black-hover;
}

.row-borda-dahora{

	@include media-breakpoint-up(lg){
		position:relative;

		&:after{
			content:'';
			display:block;
			position:absolute;
			top:7px;
			left:0;
			width:100%;
			height:1px;
			border-bottom:1px dashed #1e1b1d;
		}
		
	}

}

.internas{
	
	.bg-azul-claro{
		background-color:#FFF !important;
	}

}