.form-destaques{
	margin-bottom:30px;

	form{

		@include media-breakpoint-up(lg){
			max-width:570px;
			margin-left:auto;
			margin-right:auto;
		}

		.input-group{
			border:1px solid #e5e5e5;
			border-radius:30px;

			@include media-breakpoint-down(md){
				border:none;
			}

			.form-control,
			.custom-select{
				@include place(#8a8a8a);
				font-size:14px;
				font-family: 'montserratlight';
				border-radius:0;

				@include media-breakpoint-down(md){
					width:100%;
					border-radius:30px !important;
					border:1px solid #8a8a8a;
				}

			}

			& > *,
			.btn{
				border:none;
				box-shadow:none;
			}

			.form-control{
				border-radius:30px 0 0 30px;
				padding-left:20px;
				@include sombra(#8a8a8a);

				@include media-breakpoint-up(lg){
					min-height:50px;
				}

			}

			.input-group-prepend{
				align-items:center;
				margin-right:0;
				background-color:#FFF;

				@include media-breakpoint-down(md){
					width:100%;
					padding-top:15px;
					justify-content:center;
					padding-bottom:15px;

					&:last-child{
						padding:0;
					}

				}

			}

			.input-group-prepend:last-child .btn,
			.input-group-prepend:last-child{
				border-radius:0 30px 30px 0;
			}

		}

		.custom-select{
			border:none;
			background:none !important;
			border-left:1px solid #dbdbdb;
		}

		.btn{
			color:#000;
			font-size:19px;
			width:100%;

			@include media-breakpoint-down(md){
				background-color:#000;
				color:#FFF;
				border-radius:30px !important;
			}

		}

	}

	&.form-destaques-bg-cinza{

		form{

			.input-group{

				& > *{
					background-color:#ededed;
				}

			}

		}

	}

}