.form-newsletter-rodape{

	form{
		max-width:786px;
		margin-left:auto;
		text-align:center;
		margin-right:auto;

		@include media-breakpoint-down(md){
			padding-top:20px;
		}

		.input-group{

			.input-group-prepend{
				flex-grow:1;

				@include media-breakpoint-down(md){
					width:100%;
				}

			}

			.form-control{
				font-family: 'montserratlight';
				font-size:14px;
				@include place(#8a8a8a);
				min-height:50px;
				@include sombra(#ededed);
				border-radius:30px;
				border:none;

				@include media-breakpoint-up(lg){
					min-width:371px;
					border-radius:0;
				}

			}

			& > .form-control{
				border-radius:30px 0 0 30px;
				padding-left:18px;
				border-right:2px solid #231f20;

				@include media-breakpoint-down(md){
					border-radius:30px;
					width:100%;
				}

			}

			.form-control,
			.input-group-btn{
				background-color:#ededed;

				@include media-breakpoint-down(md){
					margin-bottom:15px;
				}

			}

			.btn{
				width:41px;
				height:41px;
				line-height:41px;
				padding:0;
				font-size:20px;
				text-align:center;
				border-radius:100%;
			}

			.input-group-btn{
				display:flex;
				align-items:center;
				padding-right:3px;
				border-radius:0 30px 30px 0;
				border:none;

				@include media-breakpoint-down(md){
					background-color:transparent;
					display:block;
					text-align:center;
					width:100%;

					.btn{
						border-radius:30px;
						width:100%;
					}


				}

			}

		}

	}

}