.botao-categorias{
	background-color:#8a2b2e;
	color:#FFF;

	@include media-breakpoint-up(xl){
		padding-right:16px;

		&:before{
			right:100%;
		}

	}

	& > .btn{
		color:inherit;
		padding:0;
		border:none;
		font-size:16px;
		display:flex;
		align-items:center;
		justify-content:center;
		height:100%;

		.fas{
			margin-right:12px;
		}

		@include media-breakpoint-down(lg){
			display:block;
			width:100%;
			padding:10px 10px;
		}

	}

	.menu-categorias{
		background-color:$theme2;
		border-right:1px solid #000;
		margin-bottom:0;
		color:#FFF;
		word-break:break-all;
		overflow:auto;

		.fas{
			@include transitionRepetitivo(transform);

			&.fa-minus{
				transform:rotate(180deg);
			}

		}

		&,
		ol,
		ul{
			list-style-type:none;
			padding-left:0;
		}

		ul,
		ol{
			width:100%;
		}

		.item{
			display:flex;
			flex-wrap:wrap;
			
			& > a{
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
				width:82%;
				flex-grow:1;
				padding:10px 15px;
				background-color:darken($theme2,10%);
			}

			& > .box-button{
				width:18%;
				@include transitionRepetitivo(border-color);
				display:flex;
				flex-wrap:wrap;

				.btn{
					width:100%;
					border-radius:0;
					font-size:18px;
					@include transitionRepetitivo();
				}

			}

		}

		.item:hover > a,
		.item:hover > .box-button{
			border-color:#FFF;
		}

	}

	.btn-theme-2{
		background-color:darken($theme2,10%);
		border-color:darken($theme2,10%);
	}

}