.form-buscas-topo{

	form{

		.input-group,
		.form-group{
			align-items:center;
			border-radius:30px;
			padding-right:6px;
			background-color:#ededed;

			.form-control{
				font-size:14px;
				@include place(#8a8a8a);
				min-height:50px;
				padding-left:28px;
				background-color:transparent;
				border:none;
				box-shadow:none;
			}

			.btn{
				font-size:19px;
				width:41px;
				height:41px;
				line-height:41px;
				padding:0;
			}

		}

	}

}