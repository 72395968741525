.faixa__topo{
	overflow: hidden;
	background: #1E1B1D;
	color: #FFF;
	position: relative;

	.container{
		display: flex;
		justify-content: space-between;
		padding-left: 175px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.redes__sociais{
		display: flex;
		align-items: center;
		white-space: nowrap;
	}

	.redes__sociais__title{
		margin-right: 10px;
	}

	.message__boas__vindas{
		position: relative;

		&::before{
			content: '';
			width: 100vw;
			height: calc(100% + 10px);
			background: #FFF;
			right: 100%;
			position: absolute;
			clip-path: polygon(
				0 0,
				calc(100% - 34px) 0,
				100% 100%,
				0 100%
			);
		}
	}
}

.topo__main{
	display: grid;
	grid-template-columns: 145px 1fr min-content min-content;
	grid-template-rows: 1fr auto auto 1fr;
	grid-template-areas:
		'LOGO S0    S1   S2'
		'LOGO TEL   TEL  TEL'
		'LOGO SEACH USER BAG'
		'LOGO S5    S6   S7'
	;
	grid-column-gap: 30px;
	align-items: center;
	color: #343434;

	.logo{
		transform: translateY(-15px);
		grid-area: LOGO;
	}

	.fones__topo{
		grid-area: TEL;
		display: flex;
	}

	.fones__topo__title{
		margin-right: 10px;
	}

	.form-buscas-topo{
		grid-area: SEACH;
	}

	.user__area{
		grid-area: USER;
		display: flex;
		position: relative;

		&::before{
			content:'';
			border-left: 1px solid rgba(#000,.2);
			height: 100%;
			position: absolute;
			right: -16px;
			top: 0
		}

		.btn{
			@include button-variant(#FFF, #FFF);
			display: grid;
			grid-template-columns: min-content 1fr;
			grid-template-rows: auto auto;
			text-align: left;

			.fas{
				font-size: 30px;
			}

			.icon,
			.fas{
				margin-right: 10px;
				grid-column: 1/2;
				grid-row: 1/3;
				align-self: center;
			}

			span{
				display: block;
				white-space: nowrap;
			}
		}
	}

	.btn__carrinho{
		grid-area: BAG;
		white-space: nowrap;
		@extend .btn;
		@include button-variant(#FFF, #FFF);

		display: grid;
		grid-template-columns: min-content 1fr;
		grid-template-rows: 1fr auto;
		text-align: left;
		align-self: stretch;

		.fas,
		.icon{
			grid-column: 1/2;
			grid-row: 1/3;
			margin-right: 15px;
			align-self: center;
		}

		span{
			grid-column: 2/3;
			grid-row: 2/3;
			align-self: flex-end;
			font-size: 16px;
		}
	}

	.btn__carrinho,
	.user__area .btn{

		&:hover,
		&:focus {
			background: #FFF;
			border-color: #FFF;
		}

		.icon,
		svg{
			flex-shrink: 0;
		}
	}

	.dropdown-menu{
		background: $theme2;
		padding: 2px 0;

		&::before{
			content:'';
			border-bottom: 10px solid $theme2;
			border-right: 10px solid transparent;
			border-left: 10px solid transparent;
			position: absolute;
			left: 50%;
			bottom: 100%;
			transform: translate(-50%, 1px);
		}

		a{
			display: block;
			padding: 10px 15px;
			color: #FFF;
		}

		.active a,
		a.active,
		a:hover{
			background: darken($theme2, 10%);
		}
	}
}

.topo__nav{
	background: $theme2;
	position: relative;

	.container{
		display: flex;
		position: relative;

		&::before{
			content:'';
			position: absolute;
			width: 100vw;
			height: 100%;
			background: #8a2b2e;
			right: 100%;
			top: 0;
			margin-right: -15px;
		}
	}

	.btn-toggle-categorias{
		border-radius: 0;
		@include button-variant(#8a2b2e, #8a2b2e);
		border-radius: 0;
		border: none;
		box-shadow: none;
	}
}


.todas__categorias{
	background: $theme2;
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	overflow: auto;
	z-index: 3080;
	width: 220px;
	transform: translate(-100%, 0);
	transition: transform .4s linear;
	border-right: 1px solid #000;

	&.todas__categorias--shown{
		transform: translate(0, 0);
	}

	&.todas__categorias--hide{
		transform: translate(-100%, 0);
	}

	.categoria:last-child{
		border-bottom: 1px solid rgba(#000, .2);
	}

	.btn{
		.fas{
			transition: transform .4s linear;
		}

		&[aria-expanded="true"] {
			.fas{
				transform: rotate(-180deg);
			}
		}
	}
}
