body,
html{
	width:100%;
	min-height:100vh;
}

#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.wrapper{
		flex-grow:1;
	}
}