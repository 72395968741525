.section-carrinho{
	padding-top:90px;
	padding-bottom:65px;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;
	background-attachment:fixed;

	.header-section{
		text-align:center;
		margin-bottom:32px !important;
	}

	.section-header-carrinho{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		padding-bottom:15px;

		@include media-breakpoint-down(md){
			justify-content:center;

			.title-header-carrinho:last-child{
				display:none;
			}

		}
		

		.title-header-carrinho{
			font-size:20px;
			color:#51a6ad;
			font-family: 'montserratbold';
		}

	}

	.section-body-carrinho{

		.carrinho-item{
			padding-top:12px;
			padding-bottom:12px;
			border-top:1px solid #dbdbdb;
			border-bottom:1px solid #dbdbdb;
			margin-bottom:12px;

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				justify-content:space-between;
				align-items:center;
			}

			@include media-breakpoint-down(md){
				text-align:center;
			}

			.carrinho-header{

				@include media-breakpoint-down(md){
					padding-bottom:12px;
				}
				
				@include media-breakpoint-up(lg){
					display:flex;
					flex-wrap:wrap;
					align-items:center;
				}

				.desc{
					font-family: 'montserratsemibold';
					font-size:17px;
					color:#726f6e;

					@include media-breakpoint-up(lg){
						padding-left:10px;
					}

				}

			}

			.carrinho-body{

				@include media-breakpoint-up(lg){
					display:flex;
					flex-wrap:wrap;
					justify-content:space-between;
					padding-right:20px;

					.btn-lata{
						margin-left:22px;
					}

				}

				.input-group-grande{
					max-width:140px;
					margin-left:auto;
					margin-right:auto;

					.btn{
						border-radius:0;
					}

					.form-control{
						text-align:center;
						border-top-color:#1d1d1d;
						border-bottom-color:#1d1d1d;
						background-color:#f1f3f3;
						@include sombra(#1d1d1d);
					}

				}

				.btn-lata{
					color:#1d1d1d;
					font-size:18px;

					@include hover-focus{
						color:$theme2;
					}

				}

			}

		}

	}

	.section-footer-carrinho{
		padding-top:38px;

		@include media-breakpoint-up(lg){
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
		}

		@include media-breakpoint-down(md){
			text-align:center;

			& > .btn{
				margin-bottom:20px;
				display:block;
				max-width:222px;
				margin-right:auto;
				margin-left:auto;
			}

		}

	}

}