.search__mobile{
	z-index: 3080;
	position: fixed;
	width: calc(100% - 30px);
	max-width: 600px;
	left: 50%;
	top: 85px;
	transform: translate(-50%, calc(-100% + -100px));
	opacity: 0;
	transition: transform .4s linear, opacity .4s linear;

	&.search__mobile--shown{
		transform: translate(-50%, 0);
		opacity: 1;
	}

	&.search__mobile--hide{
		transform: translate(-50%, calc(-100% + -100px));
		opacity: 0;
	}
}
