// Margins
@for $i from 0 through 20{
	$m: $i * 5;

	@include margin-variant($m);
}

lazyimage{
	display: block;

	.canvas-container{
		position: relative;
	}

	.cavas{
		position: absolute;
		width: 100%;
		height: 100%;
		left:0;
		top:0;
	}
}

.bg-menu{
	position:fixed;
	top:0;
	left:0;
	height:0;
	width:100%;
	opacity:0;
	@include transitionRepetitivo(opacity);
	background-color:rgba(#000,0.5);
}

body{
	transition: all .4s linear;

	&.sombra{

		.bg-menu{
			height:100%;
			opacity:1;
			z-index:990;
		}

	}

	&.menu-active{

		.btn-responsivo{

			.bar-menu{
				transform:rotate(45deg);

				&:before{
					bottom:0;
				}

				&:after{
					top:0;
				}

				@include beforeAfter{
					transform:rotate(90deg);
				}

			}

		}

		.topo-content{
			left:0;
		}

	}

	&.categorias-active{

		@media(min-width:1350px){
			padding-left:220px;
		}

		.menu-categorias{

			@include media-breakpoint-up(xl){
				left:0;
			}

			.item-categoria{

				@include media-breakpoint-up(xl){
					display:flex;
					flex-wrap:wrap;
				}

				a{
					flex-grow:1;
				}

			}

		}

	}

}

.rotate-90{
	transform:rotate(90deg);
}

.borda-legal{
	position:relative;

	&:before{
		content:'';
		display:block;
		position:absolute;
		top:calc(50% - 4px);
		right:4px;
		border:8px solid transparent;
		border-top-color:#bebebe;
	}

}


.modal__esqueci__senha{
	.modal-content,
	.modal-body,
	.modal-header,
	.modal-footer{
		border-radius: 0;
	}

	.modal-header,
	.modal-body,
	.modal-footer{
		border: none
	}

	.modal-header{
		background: $theme2;
		color: #FFF;
	}

	.close{
		color: #FFF;
	}

	.form-group{
		position: relative;

		.form-control{
			position: relative;
			z-index: 0;

			&::placeholder{
				color: transparent;
			}

			&:focus,
			&:not(:placeholder-shown) {
				& ~ label{
					font-size: 12px;
					top: 0;
					transform: translateY(-50%);
				}
			}
		}

		label{
			position: absolute;
			left: calc(.75rem - 4px);
			top: calc(.375rem + 1px);
			font-size: 1rem;
			transition: all .3s linear;
			background: #FFF;
			padding: 0 5px;
			z-index: 2;
		}
	}

	.modal-title{
		text-align: center;
		font-size: 25px;
		font-family: "montserratbold";
	}
}


.modal-backdrop{
	backdrop-filter: blur(4px);
	background: rgba(#000,.5);

	&.show{
		opacity: 1;
	}
}
